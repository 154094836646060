import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import TOP_NAVIGATION from "@cloudscape-design/components/top-navigation";
import awsImg from '../imgs/aws-logo.png';

export default function TopNavigation({user, signOut}) {
  return (
    <TOP_NAVIGATION
      identity={{
        href: "/",
        title: "EL-CS",
        logo: {
          src:
            // "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSIzMXB4IiB2aWV3Qm94PSIwIDAgNDMgMzEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxyZWN0IGZpbGw9IiMyMzJmM2UiIHN0cm9rZT0iI2Q1ZGJkYiIgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0MiIgaGVpZ2h0PSIzMCIgcng9IjIiPjwvcmVjdD4KICAgICAgICA8dGV4dCBmb250LWZhbWlseT0iQW1hem9uRW1iZXItUmVndWxhciwgQW1hem9uIEVtYmVyIiBmb250LXNpemU9IjEyIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHRzcGFuIHg9IjkiIHk9IjE5Ij5Mb2dvPC90c3Bhbj4KICAgICAgICA8L3RleHQ+CiAgICA8L2c+Cjwvc3ZnPgo=",
            awsImg,
            alt: "Elevate Consulting Skills"
        }
      }}
      utilities={[
        {
          type: "button",
          text: "Wiki",
          href: "https://w.amazon.com/bin/view/AWS/ProServe/GFS/GFS-CAA/ElevateConsultingSkills/",
          external: true,
          externalIconAriaLabel: " (opens in a new tab)"
        },
        // {
        //   type: "button",
        //   iconName: "notification",
        //   title: "Notifications",
        //   ariaLabel: "Notifications (unread)",
        //   badge: true,
        //   disableUtilityCollapse: false
        // },
        // {
        //   type: "menu-dropdown",
        //   iconName: "settings",
        //   ariaLabel: "Settings",
        //   title: "Settings",
        //   items: [
        //     {
        //       id: "settings-org",
        //       text: "Organizational settings"
        //     },
        //     {
        //       id: "settings-project",
        //       text: "Project settings"
        //     }
        //   ]
        // },
        {
          type: "menu-dropdown",
          text: user.attributes.family_name ? user.attributes.given_name+" "+user.attributes.family_name : user.attributes.given_name,
          description: user.attributes.email,
          iconName: "user-profile",
          onItemClick: signOut,
          items: [
            // { id: "profile", text: "Profile" },
            // { id: "preferences", text: "Preferences" },
            // { id: "security", text: "Security" },
            // {
            //   id: "support-group",
            //   text: "Support",
            //   items: [
            //     {
            //       id: "documentation",
            //       text: "Documentation",
            //       href: "#",
            //       external: true,
            //       externalIconAriaLabel:
            //         " (opens in new tab)"
            //     },
            //     { id: "support", text: "Support" },
            //     {
            //       id: "feedback",
            //       text: "Feedback",
            //       href: "#",
            //       external: true,
            //       externalIconAriaLabel:
            //         " (opens in new tab)"
            //     }
            //   ]
            // },
            { id: "signOut", text: "Sign out", onItemClick: signOut }
          ]
        }
      ]}
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu"
      }}
    />
  );
}