import * as React from "react";
import {
  Link as RouterLink,
  Route,
  Navigate,
  NavLink,
  useLocation,
} from "react-router-dom";

import {
  ContentLayout,
  AppLayout,
  Container,
  Header,
  SpaceBetween,
  Link,
  Button,
  Alert,
  BreadcrumbGroup,
  TextContent,
  Box,
  Cards,
  Icon,
} from "@cloudscape-design/components";

export default function Results() {
  const { state } = useLocation();
  const level = Number(state.overallGrade?.skillLevel.slice(-3));

  // console.log("Results State ===> ", state);

  if (!state) {
    return <Navigate to="/" />;
  }

  const categoryArray = state.category;

  // const categories = categoryArray.map((categories, index) => (
  //   <>
  //     <li className="surveyCategories" key={index}>
  //       <Box variant="p">
  //         <div className="categoryName">
  //           <span>{categories.name}</span>
  //           <div className="dash"></div>
  //           <span>{categories.grades[0].skillLevel}</span>
  //         </div>
  //         <p>
  //           <small>{categories.description}</small>
  //         </p>
  //         <p>{categories.grades[0].feedback[0].content}</p>
  //         <p>
  //           <a href={categories.grades[0].feedback[0].url}>
  //             {categories.grades[0].feedback[0].url}
  //           </a>
  //         </p>
  //       </Box>
  //     </li>
  //     <hr />
  //   </>
  // ));

  let className100 = "step",
    className200 = "step",
    className300 = "step",
    className400 = "step";

  if (level >= 100) {
    className100 = "step in-progress";
  }
  if (level >= 200) {
    className100 = "step complete";
    className200 = "step in-progress";
  }
  if (level >= 300) {
    className200 = "step complete";
    className300 = "step in-progress";
  }
  if (level >= 400) {
    className300 = "step complete";
    className400 = "step complete";
  }

  const CompletedCheck = () => {
    return <Icon className="completedCheck" name="check" variant="inverted" />;
  };

  return (
    <>
      <AppLayout
        toolsHide={true}
        navigationHide={true}
        contentHeader={
          <SpaceBetween>
            <Header
              variant="h1"
              // info={<Link>Info</Link>}
              // description={
              //   <>
              //     Welcome to ELEVATE Consulting Skills. Learn more about{" "}
              //     <Link
              //       href="https://w.amazon.com/bin/view/AWS/ProServe/GFS/GFS-CAA/ConsultingSkillsLeveUp/"
              //       external
              //       externalIconAriaLabel="Opens in a new tab"
              //     >
              //       ELEVATE Consulting Skills.
              //     </Link>
              //   </>
              // }
            >
              Self Assessment Results
            </Header>
            {/* <Alert>This is a generic alert.</Alert> */}
          </SpaceBetween>
        }
        content={
          <Container>
            <SpaceBetween size="s">
              <TextContent>
                <div className="results">
                  <SpaceBetween size="xl">
                    <h2 className="resultsHeader">Your Results</h2>
                    <div className="userResults">
                      <div>
                        <span className="overallLevel">Overall Level - </span>
                      </div>

                      <div>
                        <span className="overallGrade">
                          {state.overallGrade?.skillLevel}
                        </span>
                      </div>
                    </div>
                    {/* TIMELINE */}
       
                    <div className="progress-bar">
                      <div className={className100}>
                        Level 100
                        <div className="node">
                          {level >= 100 && <CompletedCheck />}
                        </div>
                      </div>

                      <div className={className200}>
                        Level 200
                        <div className="node">
                          {level >= 200 && <CompletedCheck />}
                        </div>
                      </div>
                      <div className={className300}>
                        Level 300
                        <div className="node">
                          {level >= 300 && <CompletedCheck />}
                        </div>
                      </div>
                      <div className={className400}>
                        Level 400
                        <div className="node">
                          {level >= 400 && <CompletedCheck />}
                        </div>
                      </div>
                    </div>
                    <div className="progress-message">
                      <TextContent>
                        {level < 400 && (
                          <Box textAlign="center" variant="p">
                            Your next target level is <strong>Level {level + 100}</strong>. <br />
                            Below is the recommended learning path to reach your target level.
                          </Box>
                        )}
                        {level === 400 && (
                          <Box textAlign="center" variant="p">
                            You have reached the highest level of <strong>Level 400</strong>. <br />
                          </Box>
                        )}
                      </TextContent>
                    </div>

                    {/* TIMELINE */}

                    {/*  */}
                    <div className="cards">
                      <h2>Categories</h2>
                      <Cards
                        className="resultsCards"
                        ariaLabels={{
                          itemSelectionLabel: (e, n) => `select ${n.name}`,
                          selectionGroupLabel: "Item selection",
                        }}
                        cardDefinition={{
                          header: (item) => (
                            <h3 fontSize="heading-m">{item.name}</h3>
                          ),
                          sections: [
                            // {
                            //   id: "description",
                            //   header: "Description",
                            //   content: (item) => "",
                            //   //   content: (item) => item.description,
                            // },
                            {
                              id: "feedback",
                              header: "Resource recommendations",
                              content: (item) => (
                                <>
                                  <p>
                                    <a
                                      href={item.grades[0]?.feedback[0].url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {item.grades[0]?.feedback[0].url}
                                    </a>
                                  </p>
                                </>
                              ),
                            },
                          ],
                        }}
                        cardsPerRow={[
                          { cards: 1 },
                          { minWidth: 500, cards: 1 },
                        ]}
                        items={categoryArray}
                        loadingText="Loading resources"
                        empty={
                          <Box textAlign="center" color="inherit">
                            <b>No resources</b>
                            <Box
                              padding={{ bottom: "s" }}
                              variant="p"
                              color="inherit"
                            >
                              No resources to display.
                            </Box>
                            <Button>Create resource</Button>
                          </Box>
                        }
                      />
                    </div>
                  </SpaceBetween>
                </div>
              </TextContent>
            </SpaceBetween>
          </Container>
        }
      />
    </>
  );
}
