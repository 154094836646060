import { useRouteError } from "react-router-dom";

import { Outlet, Link as RouterLink } from "react-router-dom";

import {
  AppLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import "@cloudscape-design/global-styles";
import TopNavigation from "../components/top-navigation";
import { Survey } from "survey-react-ui";
import { questions } from "../questions";
import { Model, StylesManager } from "survey-core";
import "survey-core/defaultV2.min.css";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  
  return (
    <>
      <TopNavigation></TopNavigation>
      <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
    </>
  );
}
