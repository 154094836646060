export const questions = {
    "title": "Please answer the following questions.",
    "description": "On completing each question, click the 'Next' button to continue to the next category.",
    "logoPosition": "right",
    "completedHtml": "<h2>Thank you for taking the time to complete this questionnaire.</h2><p>Based on your responses, recommended to take this learning... ",
    "pages": [
        {
            "title": "Presentation Skills",
            "name": "whats",
            "elements": [
                {
                    "type": "radiogroup",
                    "isRequired": true,
                    "name": "question1",
                    "title": "How comfortable are you with handling a project kickoff/ Customer introduction meetings solo ?",
                    "choices": [
                    {"value": 1,"text": "Very Uncomfortable"},
                    {"value": 2,"text": "Slightly Comfortable"},
                    {"value": 3,"text": "Somewhat Comfortable"},
                    {"value": 4,"text": "Very Comfortable"}
                    ],
                    "colCount": 4
                },
                
       {
        "type": "radiogroup",
        "name": "question2",
        "isRequired": true,
        "title": "How often have you delivered presentations perfectly with excellent command over content, audience, discussions on point and handing q&q ?",
        "choices": [
         {
          "value": 1,
          "text": "Very Uncomfortable"
         },
         {
          "value": 2,
          "text": "Slightly Comfortable"
         },
         {
          "value": 3,
          "text": "Somewhat Comfortable"
         },
         {
          "value": 4,
          "text": "Very Comfortable"
         }
        ],
        "colCount": 4
       },
       {
        "type": "radiogroup",
        "isRequired": true,
        "name": "question3",
        "title": "How comfortable are you in delivering presentations that meets AWS messaging & language standards ?",
        "choices": [
         {
          "value": 1,
          "text": "Very Uncomfortable"
         },
         {
          "value": 2,
          "text": "Slightly Comfortable"
         },
         {
          "value": 3,
          "text": "Somewhat Comfortable"
         },
         {
          "value": 4,
          "text": "Very Comfortable"
         }
        ],
        "colCount": 4
       }
      ],
     },


     
     {
      "name": "page2",
      "elements": [
       {
        "type": "radiogroup",
        "isRequired": true,
        "name": "question4",
        "title": "How comfortable are you at writing a narrative or PR/FAQ type documents?",
        "choices": [
         {
          "value": 1,
          "text": "Very Uncomfortable"
         },
         {
          "value": 2,
          "text": "Slightly Comfortable"
         },
         {
          "value": 3,
          "text": "Somewhat Comfortable"
         },
         {
          "value": 4,
          "text": "Very Comfortable"
         }
        ],
        "colCount": 4
       },
       {
        "type": "radiogroup",
        "isRequired": true,
        "name": "question5",
        "title": "How confident are you that your narrative/document effectively conveys your thoughts to the reader?",
        "choices": [
         {
          "value": 1,
          "text": "Very Uncomfortable"
         },
         {
          "value": 2,
          "text": "Slightly Comfortable"
         },
         {
          "value": 3,
          "text": "Somewhat Comfortable"
         },
         {
          "value": 4,
          "text": "Very Comfortable"
         }
        ],
        "colCount": 4
       }
      ],
      "title": "Written Communication"
     },
     {
      "name": "page3",
      "elements": [
       {
        "type": "radiogroup",
        "isRequired": true,
        "name": "question6",
        "title": "How comfortable are you keeping conversation on track?",
        "choices": [
         {
          "value": 1,
          "text": "Very Uncomfortable"
         },
         {
          "value": 2,
          "text": "Slightly Comfortable"
         },
         {
          "value": 3,
          "text": "Somewhat Comfortable"
         },
         {
          "value": 4,
          "text": "Very Comfortable"
         }
        ],
        "colCount": 4
       },
       {
        "type": "radiogroup",
        "isRequired": true,
        "name": "question7",
        "title": "How comfortable are you in adapting to the skill level of the audience when presenting or explaining an idea/topic ?",
        "choices": [
         {
          "value": 1,
          "text": "Very Uncomfortable"
         },
         {
          "value": 2,
          "text": "Slightly Comfortable"
         },
         {
          "value": 3,
          "text": "Somewhat Comfortable"
         },
         {
          "value": 4,
          "text": "Very Comfortable"
         }
        ],
        "colCount": 4
       }
      ],
      "title": "Verbal Communication"
     }
    ]
   }