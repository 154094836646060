import { useCallback, useEffect, useState } from "react";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import {
  AppLayout,
  Container,
  Header,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import "@cloudscape-design/global-styles";
import { Survey } from "survey-react-ui";
import { questions } from "../questions"; // OLD QUESTION SET
// import { questionsMap } from "../questionsMap";
import SurveyCompleteMsg from "../components/surveyCompleteMsg";
import SurveyResults from "../components/survey-results";
import { Model, StylesManager } from "survey-core";
import "survey-core/defaultV2.min.css";
import { API, graphqlOperation } from "@aws-amplify/api";

export default function SelfAssessment() {
  const [survey, setSurvey] = useState(new Model()); // UNCOMMENT TO USE DB QUESTIONS
  const [surveyData, setSurveyData] = useState(null); // UNCOMMENT TO USE DB QUESTIONS
  // const [survey, setSurvey] = useState(new Model(questions)); // COMMENT TO USE DB QUESTIONS
  const [surveyLoaded, setSurveyLoaded] = useState(false); // UNCOMMENT TO USE DB QUESTIONS
  // const [surveyLoaded, setSurveyLoaded] = useState(true); // COMMENT TO USE DB QUESTIONS
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [surveyResults, setSurveyResults] = useState({});
  const [surveyResultsLoaded, setSurveyResultsLoaded] = useState(false);

  const navigate = useNavigate();

  const AUTH_USER = window.localStorage.getItem(
    `CognitoIdentityServiceProvider.3nmn97jkoso7sbcpealkteeqq1.LastAuthUser`
  );
  const JWT = window.localStorage.getItem(
    `CognitoIdentityServiceProvider.3nmn97jkoso7sbcpealkteeqq1.${AUTH_USER}.idToken`
  );

  const authHeader = {
    headers: {
      Authoization: "Bearer " + JWT,
      "Content-Type": "application/json",
    },
  };

  const questionsMapTemplate = {
    title: "Please answer the following questions.",
    description:
      "Once completing each question, click the 'Next' button to continue to the next category.",
    logoPosition: "right",
    pages: [],
  };

  const nameConversion = {
    id: "name",
    code: "code",
    version: "version",
    question: "question",
    questionType: "questionType",
    answer: "answer",
    description: "description",
    answerOptions: "answerOptions",
    startDate: "startDate",
    updateDate: "updateDate",
    weight: "weight",
    // QUESTION TYPES
    SingleChoice: "radiogroup",
    MultiChoice: "checkbox",
  };

  const startMyTest = async () => {
    const mutation = `
      mutation startMyTest {
        startMyTest(input: {code: "CSLP", version: "CSLP-01"}) {
          id
          question
          questionType
          category
          answerText
          answerOptions {
            id
            option
          }
        }
      }
    `;

    // const response = await API.graphql(graphqlOperation(mutation), authHeader); //PUT THIS BACK IN WHEN DB IS FIXED
    let response = await API.graphql(graphqlOperation(mutation), authHeader); //REMOVE WHEN DB FIXED
    console.log("startMyTest response ===> ", response);
    if (response.data.startMyTest.length === 0) { //REMOVE IF BLOCK WHEN DB FIXED
      response = await API.graphql(graphqlOperation(mutation), authHeader);
      console.log("startMyTest response ===> ", response);
    }
      

    const buildPageAndQuestions = (response) => {
      // Helper functions
      const checkPageExists = (question) => {
        for (let page of questionsMapTemplate.pages) {
          if (page.name === question.category) {
            return true;
          }
        }
        return false;
      };
      const addPage = (question) => {
        questionsMapTemplate.pages.push({
          title: question.category,
          name: question.category,
          elements: [],
        });
      };
      const addQuestionToPage = (question) => {
        for (let page of questionsMapTemplate.pages) {
          if (page.name === question.category) {
            // SET CHOICES
            let choicesOptions = [];
            for (let answerOption of question.answerOptions) {
              choicesOptions.push({
                value: answerOption.id,
                text: answerOption.option,
              });
            }
            let questionTemplate = {
              type: nameConversion[question.questionType],
              isRequired: true,
              name: question.id,
              title: question.question,
              choices: choicesOptions,
              colCount: question.answerOptions.length,
            };
            page.elements.push(questionTemplate);
          }
        }
      };
      // Main function
      for (let question of response.data.startMyTest) {
        if (!checkPageExists(question)) {
          addPage(question);
        }
        addQuestionToPage(question);
      }
      return questionsMapTemplate;
    };

    await setSurveyData(response.data.startMyTest);
    await setSurvey(new Model(JSON.stringify(buildPageAndQuestions(response))));
    await setSurveyLoaded(true);
    console.log("===survey===", survey);
    console.log("===surveyData===", surveyData);
  };

  const answerMyTestQuestion = async (mutation) => {
  const result = await API.graphql(graphqlOperation(mutation));
  };

  const completeMyTest = async () => {
    const mutation = `
    mutation MyMutation {
      completeMyTest(input: {code: "CSLP", version: "CSLP-01", attempt: 1}) {
        attempt
        category {
          description
          grades {
            feedback {
              content
              url
            }
            scorePercent
            scorePercentMax
            scorePercentMin
            skillLevel
          }
          name
          weight
        }
        code
        user
        version
        overallGrade {
          feedback {
            content
            url
          }
          scorePercent
          scorePercentMax
          scorePercentMin
          skillLevel
        }
      }
    }
    `;

    const result = await API.graphql(graphqlOperation(mutation), authHeader);
    setSurveyResults(result.data.completeMyTest);
    setTimeout(() => {
      setSurveyResultsLoaded(true);
    }, 100);

    navigate("/results", { state: result.data.completeMyTest });
  };

  const surveyComplete = useCallback(async (sender) => {
    // CONSOLE LOG SURVEYJS RESULT
    // const results = JSON.stringify(sender.data);
    // console.log(results);

    // COMPLETE TEST
    setSurveyCompleted(true);
    await completeMyTest();
    // console.log('===surveyResults==2=>',surveyResults)
    // TRADITIONAL REST SOLUTION
    // saveSurveyResults(
    //   "https://localhost:3000/api/survey/" + SURVEY_ID,
    //   sender.data
    // )
  }, []);

  // SURVEY JS SETTINGS
  survey.onComplete.add(surveyComplete);
  survey.showProgressBar = "bottom";
  survey.showCompletedPage = false;
  survey.onValueChanged.add((sender, { value, name }) => {
    if (Array.isArray(value)) {
      value = "[" + value + "]";
    }

    const questionAnswered = `
      mutation answerMyTestQuestion {
        answerMyTestQuestion(input: {attempt: 1, 
          code: "CSLP", version: "CSLP-01", id: ${name}, answer: ${value}})
        }
        `;

    answerMyTestQuestion(questionAnswered);
  });

  const saveSurveyResults = (url, data) => {
    const request = new XMLHttpRequest();
    request.open("POST", url);
    request.setRequestHeader("Content-Type", "application/json");
    request.addEventListener("load", () => {
      // Handle "load" event
    });
    request.addEventListener("error", () => {
      // Handle "error" event
    });
    request.send(JSON.stringify(data));
  };

  //USE EFFECT
  useEffect(() => {
    startMyTest(); // UNCOMMENT TO USE DB QUESTIONS

  }, []);

  //COMPONENTS
  const surveyRender = surveyLoaded ? (
    !surveyCompleted && <Survey model={survey} />
  ) : (
    <div>
      Loading... <Spinner />
    </div>
  );

  const onSurveyCompleteComponent = surveyCompleted
    ? !surveyResultsLoaded && <SurveyCompleteMsg />
    : null;

  return (
    <>
      <AppLayout
        toolsHide={true}
        navigationHide={true}
        contentHeader={
          <Header
            variant="h1"
            // description={
            //   <>
            //     Self Assessment for Consulting Skills LevelUp. Learn more
            //     about{" "}
            //     <Link
            //       href="https://w.amazon.com/bin/view/AWS/ProServe/GFS/GFS-CAA/ConsultingSkillsLeveUp/"
            //       external
            //       externalIconAriaLabel="Opens in a new tab"
            //     >
            //       Consulting Skills LevelUp.
            //     </Link>
            //   </>
            // }
          >
            Self Assessment
          </Header>
        }
        content={
          <Container>
            <SpaceBetween size="s">
              {surveyRender}
              {onSurveyCompleteComponent}
            </SpaceBetween>
          </Container>
        }
      />
    </>
  );
}
