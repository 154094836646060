import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./routes/error-page";
import Contact from "./routes/contact";
import SelfAssessment from "./routes/self-assessment";
import Results from "./routes/results";
import Index from "./routes/index";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Index /> },
      { path: "contacts/:contactId", element: <Contact /> },
      { path: "self-assessment/", element: <SelfAssessment /> },
      { path: "results/", element: <Results /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // UNCOMMENT STRICT MODE FOR PRODUCTION, CAUSES DOUBLE USEEFFECTS IN DEV
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
