import React, { useState, useEffect } from "react";
import { Outlet, Link as RouterLink } from "react-router-dom";
import {
  AppLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Button,
  TopNavigation,
} from "@cloudscape-design/components";
import "@cloudscape-design/global-styles";
import { Survey } from "survey-react-ui";
import { Model, StylesManager } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Amplify, Auth, Hub } from "aws-amplify";
import { API, graphqlOperation } from "@aws-amplify/api";

import "./App.css";
import TopNavigationComponent from "./components/top-navigation";
import { questions } from "./questions";
import awsconfig from "./aws-exports";
import { getAllTests } from "./graphql/queries";
import { startMyTest } from "./graphql/mutations";
import { withAuthenticator, Authenticator } from "@aws-amplify/ui-react";

Amplify.configure(awsconfig);

export default function Root() {
  const survey = new Model(questions);
  const [user, setUser] = useState(null);
  const [username, setusername] = useState(null);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    const userGet = async () => {
      const userData = await getUser();
      setUser(userData);
    };
    userGet();

  }, []);

  async function getUser() {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      // console.log(userData);
      return userData;
    } catch {
      return console.log("Not signed in");
    }
  }

  const signIn = () => {
    Auth.federatedSignIn({ customProvider: "AmazonFederate" });
  };

  const signOut = () => {
    console.log("Sign Out Clicked");
    Auth.signOut({ customProvider: "AmazonFederate" });
  };

  
  
  
  const LoginContent = () => (
    <Container class="Sign-in" header={<Header variant="h2">Sign-in</Header>}>
      <h3>
        Amazon employees, please sign-in to use Elevate Consulting Skills.
      </h3>
      <div>
        {/* <Button className='btn btn-primary btn-block lg-text bold' onClick={signIn}>Signin with Midway</Button> */}
        <Button onClick={signIn}>Signin with Midway</Button>
      </div>
    </Container>
  );

  return (
    <>
      {!user && (
        <div>
          <div className="awsui">
            <AppLayout
              footerSelector="#footer"
              headerSelector="#navbar"
              navigationHide={true}
              toolsHide={true}
              content={<LoginContent />}
            />
          </div>
        </div>
      )}
      {user && (
        <>
          <TopNavigationComponent user={user} signOut={signOut} />
          {/* FOR TESTING */}
          {/* <Button onClick={() => getMyCompletedTests()}>Get My Completed Tests</Button> */}
          {/* <Button onClick={()=> getAllTestsFn()}>Get All Tests</Button> */}
          {/* <Button onClick={()=> startMyTestFn()}>Start my test</Button> */}
          <div className="detail">
            <Outlet />
          </div>
        </>
      )}
    </>
  );
}
