import { useEffect, useState } from "react";
import {
  Link as RouterLink,
  Route,
  Navigate,
  NavLink,
  useNavigate,
} from "react-router-dom";
import { API, graphqlOperation } from "@aws-amplify/api";

import {
  ContentLayout,
  AppLayout,
  Container,
  Header,
  SpaceBetween,
  Link,
  Button,
  Alert,
  BreadcrumbGroup,
  TextContent,
  Spinner,
  Box,
  Cards,
} from "@cloudscape-design/components";

export default function Home() {
  const [tests, setTests] = useState(null);
  const [testsLoaded, setTestsLoaded] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [testResultsLoaded, setTestResultsLoaded] = useState(false);
  const [AUTH_USER, setAUTH_USER] = useState(
    window.localStorage.getItem(
      `CognitoIdentityServiceProvider.3nmn97jkoso7sbcpealkteeqq1.LastAuthUser`
    )
  );
  const [JWT, setJWT] = useState(
    window.localStorage.getItem(
      `CognitoIdentityServiceProvider.3nmn97jkoso7sbcpealkteeqq1.${AUTH_USER}.idToken`
    )
  );

  const authHeader = {
    headers: {
      Authoization: "Bearer " + JWT,
      "Content-Type": "application/json",
    },
  };

  const navigate = useNavigate();

  const completeMyTest = async () => {
    const mutation = `
    mutation MyMutation {
      completeMyTest(input: {code: "CSLP", version: "CSLP-01", attempt: 1}) {
        attempt
        category {
          description
          grades {
            feedback {
              content
              url
            }
            scorePercent
            scorePercentMax
            scorePercentMin
            skillLevel
          }
          name
          weight
        }
        code
        user
        version
        overallGrade {
          feedback {
            content
            url
          }
          scorePercent
          scorePercentMax
          scorePercentMin
          skillLevel
        }
      }
    }
    `;

    const result = await API.graphql(graphqlOperation(mutation), authHeader);
    await setTestResults(result.data.completeMyTest);
    await setTestResultsLoaded(true);
    return result;
  };

  useEffect(() => {
    const getMyCompletedTests = async () => {
      const query = `
      query MyQuery {
        getMyCompletedTests {
          code
        }
      }
      `;

      const result = await API.graphql(graphqlOperation(query), authHeader);
      await setTests(result.data.getMyCompletedTests);
      await setTestsLoaded(true);
      if (result.data.getMyCompletedTests[0]?.code) {
        await completeMyTest();
      }
    };

    getMyCompletedTests();
  }, []);

  const reviewButtonClicked = () => {
    navigate("/results", { state: testResults });
  };

  //COMPONENTS
  const assessmentStatus = testsLoaded
    ? tests.length > 0 && (
        <>
          <span>Review your last self-assessment results.</span>
          <Button variant="primary" onClick={reviewButtonClicked}>
            Review Results
          </Button>
        </>
      )
    : null;

  const buttonTakeSelfAssessment = testsLoaded ? tests.length === 0 && (
    <>
      <span>
        Start your journey today toward consulting excellence by taking a
        Self-assessment survey. Find out where you stand and how to grow in your
        consulting journey.
      </span>
      <RouterLink to={"../self-assessment"} relative="path">
        <Button variant="primary">Start Self-Assessment</Button>
      </RouterLink>
    </>
  ) : null;

  return (
    <>
      <AppLayout
        toolsHide={true}
        navigationHide={true}
        contentHeader={
          <SpaceBetween>
            <Header
              variant="h1"
              // info={<Link>Info</Link>}
              // description={
              //   <>
              //     Welcome to ELEVATE Consulting Skills. Learn more about{" "}
              //     <Link
              //       href="https://w.amazon.com/bin/view/AWS/ProServe/GFS/GFS-CAA/ConsultingSkillsLeveUp/"
              //       external
              //       externalIconAriaLabel="Opens in a new tab"
              //     >
              //       ELEVATE Consulting Skills.
              //     </Link>
              //   </>
              // }
            >
              ELEVATE CONSULTING SKILLS
            </Header>
            {/* <Alert>This is a generic alert.</Alert> */}
          </SpaceBetween>
        }
        content={
          <SpaceBetween size="l">
            <Container>
              <SpaceBetween size="s">
                <TextContent>
                  <SpaceBetween size="s">
                    <h1>Home</h1>
                    <p>
                      Project complexity, volume, and size have exponentially
                      increased year over year. Delivery team's consulting
                      skills and acumen need to keep pace with the growth and
                      complexity of engagements. At present, other than an
                      initial new hire onboarding and episodic project
                      shadowing, no long-term mechanism exists for ProServe
                      consultants to individually evaluate their current
                      consulting competencies, identify areas for
                      self-improvement, and develop a personalized "upskilling"
                      road map.
                    </p>
                    <p>
                      The Elevate Consulting Skills initiative aims to bridge
                      these gaps by identifying and incorporating avenues and
                      mechanisms to develop the consulting skills for our
                      consultants. Learn more about this initiative at the{" "}
                      <a
                        href="https://w.amazon.com/bin/view/AWS/ProServe/GFS/GFS-CAA/ElevateConsultingSkills/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Elevate Consulting Skills Wiki
                      </a>
                    </p>

                    {/* <h2>Self Assessment</h2> */}
                    {/* {!testsLoaded && <Spinner />} */}
                    {/* {testsLoaded && assessmentStatus} */}
                    {/* NEXT BUTTON FOR TESTING LOCALLY */}
                    {/* <RouterLink to={"../self-assessment"} relative="path"> */}
                    {/* <Button variant="primary"> Start Self-Assessment </Button> */}
                    {/* </RouterLink> */}
                  </SpaceBetween>
                </TextContent>
              </SpaceBetween>
            </Container>
            <Container>
              <SpaceBetween size="s">
                <h2>Self Assessment</h2>
                {/* <Link href="#">Secondary link</Link> */}
                {!testsLoaded && <Spinner />}
                {assessmentStatus}
                {buttonTakeSelfAssessment}
                {/* NEXT BUTTON FOR TESTING LOCALLY */}
                {/* <RouterLink to={"../self-assessment"} relative="path">
                          <Button variant="primary">
                            Start Self-Assessment
                          </Button>
                        </RouterLink> */}
              </SpaceBetween>
            </Container>
          </SpaceBetween>
        }
      />
    </>
  );
}
