import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import Spinner from "@cloudscape-design/components/spinner";

export default function SpinnerComponent() {
  return (
    <div>
    <h2>Thank you for taking the time to complete this questionnaire.</h2>
    <p>Please wait while we calculate your answers, a result will show shortly... </p>
     <Spinner size="large" />
    </div>
  );
}