/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
let signIn = "https://www.consulting-skills.elevate.proserve.aws.dev/";
let signOut = "https://www.consulting-skills.elevate.proserve.aws.dev/";

if (process.env.NODE_ENV === "development") {
    signIn = "http://localhost:3000/";
    signOut = "http://localhost:3000/";
}

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://h3b7yazpurdy7eehtia4c7m47y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    // "aws_appsync_authenticationType": "API_KEY",
    // "aws_appsync_apiKey": "da2-romusuqzunhzdihxj2blzkejbi",
    //AUTH TYPE IS AMAZON_COGNITO_USER_POOLS
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
    //AUTH TYPE OPENID_CONNECT
    // 'aws_appsync_authenticationType': 'OPENID_CONNECT',
    //Staging User Pool
    "aws_cognito_identity_pool_id": "us-east-1:1bb4ec75-e87f-4ef8-a4c2-af61a1b6605d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_j4eVIxbly",
    "aws_user_pools_web_client_id": "3nmn97jkoso7sbcpealkteeqq1",
    //CSLPUserPool
    // "aws_cognito_identity_pool_id": "us-east-1:b1a97202-6773-4dd2-b7fd-a41bed3413b8",
    // "aws_cognito_region": "us-east-1",
    // "aws_user_pools_id": "us-east-1_Gbf4WaATw",
    // "aws_user_pools_web_client_id": "3qnrnrk6k1kppmjuruo043qpfp",
    "oauth": {
        "domain": "consulting1016004ebed-6004ebed-staging.auth.us-east-1.amazoncognito.com",
        // "domain": "CSLPUserPool.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        // "redirectSignIn": "http://localhost:3000/",
        // "redirectSignOut": "http://localhost:3000/",
        "redirectSignIn": signIn,
        "redirectSignOut": signOut,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
